var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a target=\"_blank\" rel=\"noopener noreferrer\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkHrefAppStoreUrl") || (depth0 != null ? lookupProperty(depth0,"linkHrefAppStoreUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkHrefAppStoreUrl","hash":{},"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":78}}}) : helper)))
    + "\"\n        class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":15},"end":{"line":4,"column":137}}})) != null ? stack1 : "")
    + " ws10-app-store-buttons__apple-store\">\n        <div class=\"ws10-app-store-buttons__image-"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdSize") || (depth0 != null ? lookupProperty(depth0,"stdSize") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdSize","hash":{},"data":data,"loc":{"start":{"line":5,"column":50},"end":{"line":5,"column":61}}}) : helper)))
    + "\">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":9,"column":22}}})) != null ? stack1 : "")
    + "\n        </div>\n    </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-app-store-buttons__inverse-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdSize") || (depth0 != null ? lookupProperty(depth0,"stdSize") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdSize","hash":{},"data":data,"loc":{"start":{"line":4,"column":66},"end":{"line":4,"column":77}}}) : helper)))
    + " ";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ws10-app-store-buttons__button-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdSize") || (depth0 != null ? lookupProperty(depth0,"stdSize") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"stdSize","hash":{},"data":data,"loc":{"start":{"line":4,"column":118},"end":{"line":4,"column":129}}}) : helper)))
    + " ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(require("../icon/_icon.hbs"),depth0,{"name":"_icon","hash":{"imgMidRenderIconPath":"https://eweb6.vfd2-testnet.de/media/img/icons/AppleAppstore-inverse-120x32px.svg"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(require("../icon/_icon.hbs"),depth0,{"name":"_icon","hash":{"imgMidRenderIconPath":"https://eweb6.vfd2-testnet.de/media/img/icons/AppleAppstore-120x32px.svg"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a target=\"_blank\" rel=\"noopener noreferrer\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkHrefGooglePlayUrl") || (depth0 != null ? lookupProperty(depth0,"linkHrefGooglePlayUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkHrefGooglePlayUrl","hash":{},"data":data,"loc":{"start":{"line":14,"column":55},"end":{"line":14,"column":80}}}) : helper)))
    + "\"\n        class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":15,"column":15},"end":{"line":15,"column":137}}})) != null ? stack1 : "")
    + " ws10-app-store-buttons__google-store\">\n        <div class=\"ws10-app-store-buttons__image-"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdSize") || (depth0 != null ? lookupProperty(depth0,"stdSize") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdSize","hash":{},"data":data,"loc":{"start":{"line":16,"column":50},"end":{"line":16,"column":61}}}) : helper)))
    + "\">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":20,"column":113}}})) != null ? stack1 : "")
    + "\n        </div>\n    </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.invokePartial(require("../icon/_icon.hbs"),depth0,{"name":"_icon","hash":{"imgMidRenderIconPath":"https://eweb6.vfd2-testnet.de/media/img/icons/GoogleAppstore-inverse-120x32px.svg","optSize":(depth0 != null ? lookupProperty(depth0,"stdSize") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(require("../icon/_icon.hbs"),depth0,{"name":"_icon","hash":{"imgMidRenderIconPath":"https://eweb6.vfd2-testnet.de/media/img/icons/GoogleAppstore-120x32px.svg"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a target=\"_blank\" rel=\"noopener noreferrer\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"linkHrefAppGallaryUrl") || (depth0 != null ? lookupProperty(depth0,"linkHrefAppGallaryUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"linkHrefAppGallaryUrl","hash":{},"data":data,"loc":{"start":{"line":25,"column":55},"end":{"line":25,"column":80}}}) : helper)))
    + "\"\n        class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":26,"column":15},"end":{"line":26,"column":137}}})) != null ? stack1 : "")
    + " ws10-app-store-buttons__gallary-store\">\n        <div class=\"ws10-app-store-buttons__image-"
    + alias4(((helper = (helper = lookupProperty(helpers,"stdSize") || (depth0 != null ? lookupProperty(depth0,"stdSize") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stdSize","hash":{},"data":data,"loc":{"start":{"line":27,"column":50},"end":{"line":27,"column":61}}}) : helper)))
    + "\">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optInverse") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":31,"column":22}}})) != null ? stack1 : "")
    + "\n        </div>\n    </a>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.invokePartial(require("../icon/_icon.hbs"),depth0,{"name":"_icon","hash":{"imgMidRenderIconPath":"https://eweb6.vfd2-testnet.de/media/img/icons/HuaweiAppstore-inverse-120x32.svg","optSize":(depth0 != null ? lookupProperty(depth0,"stdSize") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = container.invokePartial(require("../icon/_icon.hbs"),depth0,{"name":"_icon","hash":{"imgMidRenderIconPath":"https://eweb6.vfd2-testnet.de/media/img/icons/HuaweiAppstore_120x32.svg"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"ws10-app-store-buttons ws10-app-store-buttons__flex-direction-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"stdSize") || (depth0 != null ? lookupProperty(depth0,"stdSize") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"stdSize","hash":{},"data":data,"loc":{"start":{"line":1,"column":74},"end":{"line":1,"column":85}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkHrefAppStoreUrl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkHrefGooglePlayUrl") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"linkHrefAppGallaryUrl") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});